<template>
    <tr>
        <th class="hidden-xs-only" colspan="7"><el-divider>{{ txt[prefs.lang].discount }}</el-divider></th>
        <th class="hidden-sm-and-up" colspan="5"><el-divider>{{ txt[prefs.lang].discount }}</el-divider></th>
    </tr>
    <tr>
        <td colspan="2">{{ txt[prefs.lang].free_shipping }}</td>
        <td class="hidden-xs-only" colspan="2"></td>
        <td colspan="2">
            <span v-if="cart.total() >= settings.free_shipping_from">{{ txt[prefs.lang].yes }}! 🎉</span>
<!--            <span v-else>{{ txt[prefs.lang].no }}; {{ txt[prefs.lang].from_uah }}</span>-->
            <span v-else style="font-size: 0.9em">{{ txt[prefs.lang].for_free_shipping }} {{ Math.ceil(settings.free_shipping_from - cart.total()) }} грн</span>
        </td>
        <td>
            <el-popover
                placement="top-end"
                :width="250"
                trigger="hover"
                :title=txt[prefs.lang].free_shipping
                :content=txt[prefs.lang].free_shipping_i
            >
                <template #reference>
                    <el-button icon="el-icon-info" type="text"></el-button>
                </template>
            </el-popover>&nbsp;
        </td>
    </tr>
    <tr v-if="me && me.group.id > 1 && me.group.id < 10">
        <td colspan="2">{{ txt[prefs.lang].etern_discount }} 💚</td>
        <td class="hidden-xs-only" colspan="2"></td>
        <td colspan="2">
            <span v-if="me && me.group.id >= 2 && me.group.id < 10">-{{ 100-me.group.rate*100 }}%</span><br>
        </td>
    </tr>
    <tr>
        <td colspan="2">{{ txt[prefs.lang].loyalty_bonus }} 🌿</td>
        <td class="hidden-xs-only" colspan="2"></td>
        <td v-if="me" colspan="2">
            <span v-if="cart.credit()">
                {{ me.credit }} грн
<!--                <br>{{ txt[prefs.lang].youll_earn }} {{ cart.youll_earn_credit() }} грн-->
            </span>
            <span v-else-if="me.credit">{{ txt[prefs.lang].from }} {{ settings.credit_from }} грн</span>
            <span v-else>0 грн</span>
        </td>
        <td v-else colspan="2" style="font-size: 0.9em">{{ txt[prefs.lang].pls_login }}</td>
        <td>
            <el-popover
                placement="top-end"
                :width="250"
                trigger="hover"
                :title=txt[prefs.lang].loyalty_bonus
                :content=txt[prefs.lang].loyalty_bonus_i
            >
                <template #reference>
                    <el-button icon="el-icon-info" type="text"></el-button>
                </template>
            </el-popover>&nbsp;
        </td>
    </tr>
    <tr>
        <td colspan="2">{{ txt[prefs.lang].disc_by_sum }}</td>
        <td class="hidden-xs-only" colspan="2"></td>
        <td colspan="2">
            <span v-if="cart.discount()">-{{ Math.round(cart.discount()*cart.discountable_total()*10)/10 }} грн</span>
            <span v-else style="font-size: 0.9em">{{ txt[prefs.lang].from }} {{ settings.discount_from }} грн</span>
        </td>
        <td>
            <el-popover
                placement="top-end"
                :width="250"
                trigger="hover"
                :title=txt[prefs.lang].disc_by_sum
                :content=txt[prefs.lang].disc_by_sum_i
            >
                <template #reference>
                    <el-button icon="el-icon-info" type="text"></el-button>
                </template>
            </el-popover>&nbsp;
        </td>
    </tr>
    <tr v-if="user" style="color:#aaa;">
        <td colspan="2">[Discountable]</td>
        <td class="hidden-xs-only" colspan="2"></td>
        <td colspan="2">{{ cart.discountable_total() }} грн</td>
        <td class="hidden-xs-only"></td>
    </tr>
    <tr>
        <td colspan="2">{{ txt[prefs.lang].total_discount }}</td>
        <td class="hidden-xs-only" colspan="2"></td>
        <td colspan="2">{{ Math.round((cart.pre_discount_total() - cart.total())*100)/100 }} грн</td>
        <td></td>
    </tr>

</template>

<script>
import useCart from "@/use/cart"
import { txt } from '@/use/txt'

export default {
    setup() {
        const { me, cart, prefs, settings } = useCart()

        return { me, cart, prefs, txt, settings }
    }
}
</script>